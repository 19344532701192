import * as RN from "react-native";
import * as RNSVG from "react-native-svg";
import { styled } from "nativewind";

export const Pressable = styled(RN.Pressable);
export const Text = styled(RN.Text);
export const View = styled(RN.View);
export const Modal = styled(RN.Modal);
export const TouchableOpacity = styled(RN.TouchableOpacity);
export const Svg = styled(RNSVG.Svg);
export const Path = styled(RNSVG.Path);
export const G = styled(RNSVG.G);
