import { IconNames } from "./types";

import iconData from "./icons.json";
import { View } from "../atoms/styled";
import { Path, Svg } from "../atoms/styled";
import { ViewColors } from "../utils/colors";
import clsx from "clsx";

const iconColorClasses = {
  black: "text-black",
  white: "text-white",
  green: "text-green",
  purple: "text-purple",
  blue: "text-blue",
  red: "text-red",
  gray: "text-gray",
} as const;

export default function Icon({
  icon,
  size,
  containerClassName,
  color = "black",
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof Svg> & {
  icon: IconNames;
  size?: number;
  containerClassName?: string;
  color?: ViewColors;
}) {
  return (
    <View className={containerClassName}>
      <Svg
        width={size || "18"}
        height={size || "18"}
        viewBox="0 0 18 18"
        className={clsx(iconColorClasses[color], className)}
        {...props}
      >
        <Path
          d={iconData[icon].d as string}
          fill={props.fill || "currentColor"}
          stroke={
            iconData[icon].hasStroke ? props.fill || "currentColor" : "none"
          }
          vectorEffect="non-scaling-stroke"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </Svg>
    </View>
  );
}
